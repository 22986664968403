.container{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;


    align-items:center;
    justify-content: center;
    color:white;
}

.container > div {
    text-align: center;
    width:50%;
}

.social > svg{
    padding:1rem;
    animation: pushedin 1s ease-out;
}

.description{
    font-family: 'Jomohari', serif;
    letter-spacing: 3px;
    font-size: 0.95rem;
    padding:1rem;
    animation: blurIn ease-in 1s;
}
.email{
    letter-spacing: 3px;
    font-family: 'Krub', sans-serif;
    font-size:0.8rem;
    color:grey;
}
.me {
    width:150px;
    height:150px;
}
.me > img{
    width:150px;
    height:150px;
    border-radius: 500px;
    animation: popout 1s;
}
@keyframes blurIn{
    from{
        color: transparent;
        text-shadow: 0 0 10px #fff;
    }
    to{
        color:white;
        text-shadow: 0 0 0px transparent;
    }
}

@keyframes popout{
    from{
        width: 0px;
        height: 0px;
        opacity:0.1;
    }
    to{
        width: 150px;
        height: 150px;
        opacity:1;
    }
}

@keyframes pushedin{
    from{
        opacity: 0.1;
        padding:1.3rem;
    }
    to{
        opacity: 1;
        padding:1rem;
    }
}
