.container{
    width:100%;
    height:100vh;
    background:black;
    display:flex;
    flex-direction: column;
}
.content{
    flex-grow:4;
}

.nav{
    z-index:2;
    flex-grow: 0;
    width:35%;
    margin:auto;
    /* background:blue; */
    display:flex;
}

.nav-flex{
    cursor: pointer;
    font-size:0.7rem;
    padding:0.4rem;
    flex-grow: 5;
    /* border:1px solid black; */
    align-content: center;
    text-align: center;
    vertical-align:middle;
    font-family: 'Krub', sans-serif;
    text-decoration: none;
    letter-spacing:3px;
    margin: 0.5rem;
}
.nav-flex * {
    text-decoration: none;
    color: white;
}

.banner{
    background: white;
}
.banner * {
    font-family: 'IBM Plex Mono', monospace;
    color: black;
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {  
   .banner{
       display:none;
   }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .nav {
        width:100%;
    }
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {  }
