.container{
    padding:0;
    position:fixed;
    top: 0;
    right:0;
    width:100%;
    height:100vh;
    z-index:5;
    background-color: rgba(0,0,0,1);
    /* -webkit-backdrop-filter: blur(20px); */
    /* backdrop-filter: blur(20px); */
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    animation: slideIn 0.5s ease-out;
}

.nav{
    max-width:30%;
    position:fixed;
    top:15px;
    right:15px;
    padding: 0;
    margin:0;
   display: flex;
    z-index:10;
   animation: fadeIn 0.5s ease-in;
}
.nav-back{
    color:white;
    font-size: 1.8rem;
    padding: 1rem;
    letter-spacing: 3px;
    font-family: 'Jomolhari', serif;
    background: transparent;
    /* background:white; */
}
.nav-back svg:hover{
    transform: rotate(10deg);
    transition: all 1s;
}
.nav-back svg:active{
    transform: rotate(1000deg);
    transition: all 1s;
}


.child{
   overflow:hidden;
   flex-direction: column;
   justify-content: center;
   align-items: baseline;
   padding-left:10rem;
   padding-right:10rem;
   overflow-y: visible;
}

.exp, .proj{
    padding:1rem;
    color:white;
    opacity:0;
    animation: 1s child 1s ease-out forwards;
}
.exp h1, .proj h1{
    color: white;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.5rem;
    font-size:4rem;
}
.job{
    text-decoration:none;
    color:inherit;
    width:70%;
    display:flex;
    flex-direction: row;
}
.job-col1{
    padding:0.5rem;
    flex-grow: 1;    
    color: grey;
    font-size: 0.9rem;
    font-family: 'JetBrains Mono', monospace;
}

.job-col2{
    padding:0.5rem;
    letter-spacing: 3px;
    flex-grow: 20;    
    display:flex;
    flex-direction: column;
    transition: all ease-out 0.2s;
}
.job-col2:hover{
    padding-left:2rem;
    opacity:0.5;
}
.job-col2 span, .job-col2 small{
    font-family: 'JetBrains Mono', monospace;
    font-size:1.2rem;
}

.job-col2 small{
    color: grey;
    font-size: 0.7rem;
}

.job-col2 p {
    font-family: 'Jomolhari', serif;
    font-size:0.95rem;
    padding-bottom:1rem;
    transition: all 0.1s ease-in;
    position:relative;
}


.job-col2 p:before{
  content : "";
  position: absolute;
  left    : 0;
  bottom  : 0;
  height  : 3px;
  width   : 30px;  
  border-bottom:1px solid grey;
  animation: borderExtends 5s ease-out;
}


.proj-item{
    text-decoration: none;
    color: inherit;
    display:flex;
    flex-direction: column;
    width:70%;
    position:relative;
    transition: all ease-out 0.2s;
}
.proj-item:hover{
    margin-left:2rem;
    opacity:0.5;
}

.proj-item p:before{
  content : "";
  position: absolute;
  left    : 0;
  bottom  : 0;
  height  : 3px;
  width   : 30px;  
  border-bottom:1px solid grey;
  animation: borderExtends 5s ease-out;
}

.proj-item span, .proj-item small{
    letter-spacing: 3px;
    font-family: 'JetBrains Mono', monospace;
    font-size:1.2rem;
}

.proj-item small{
    letter-spacing: 3px;
    color: grey;
    font-size: 0.7rem;
}

.proj-item  p {
    font-family: 'Jomolhari', serif;
    font-size:0.9rem;
    letter-spacing: 3px;
}

@keyframes slideIn{
    from {
        left: 100rem;
    }
    to{
        left: 0;
    }
}
@keyframes fadeIn{
    from{
        opacity: 0.1;
    }
    to{
        opacity: 1;
    }
}
@keyframes borderExtends{
    from{
        width   : 0px;  /* or 100px */
        
        opacity: 0.1;
    }
    to{
        width   : 30px;  /* or 100px */
        opacity: 1;
    }
}

@keyframes blurIn{
    from{
        color: transparent;
        text-shadow: 0 0 10px #fff;
    }
    to{
        color:white;
        text-shadow: 0 0 0px transparent;
    }
}
@keyframes child{
    from{
        margin-left: -1rem;
        margin-right: 1rem;
        opacity:0.1;
    }
    to{
        margin-left: inherit;
        margin-right: inherit;
        opacity: 1;
    }
}

@media (max-width: 575.98px) { 
    .nav{
        top:5px;
        left:5px;
    }
    .child{
        padding:.5rem;
    }
    .child h1{
        font-size:1.5rem;
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
    .child{
        padding:1rem;
    }
    .child h1{
        font-size:3rem;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {  }
